<template>
  <div class="container">
    <div class="page-login page-slide">
      <div class="logo">
        <img src="@/assets/img/svg/logo.svg" alt="" />
      </div>

      <div class="page-login__text">
        {{ $t("sign-in-text") }}
      </div>

      <transition name="fade" appear appear-active-class="fade-enter-active">
        <div class="trans-wrap">
          <div class="page-login__btns">
            <button class="page-btn" @click="localAuth">
              <span>{{ $t("sign-in-btn") }}</span>
            </button>

            <div class="page-login__wrap">
              <a
                class="page-btn page-btn--google"
                target="_blank"
                href="https://csh.0hub.com/api/auth/google"
              >
                <GoogleSvg />
                {{ $t("sign-in-btn-google") }}
              </a>
            </div>
          </div>
        </div>
      </transition>

      <div class="default-text">
        {{ $t("sign-in-notice") }} <a href="#">{{ $t("privacy-policy") }}</a
        >.
      </div>
    </div>
  </div>
</template>

<script>
import GoogleSvg from "../components/icons/GoogleSvg";
import api from "@/api/api";
import { mapMutations } from "vuex";
import contract from "@/api/contract";
export default {
  name: "SignIn",
  computed: {
    qrCode() {
      return this.$route.query.code;
    },
  },
  methods: {
    ...mapMutations({
      setToken: "user/setToken",
      setWallet: "wallet/setWallet",
    }),
    async localAuth() {
      try {
        const wallet = JSON.parse(localStorage.getItem("wallet"));
        let response = null;
        if (wallet) {
          const signature = contract.login(wallet.mnemonic, wallet.privateKey);
          response = await api.loginLocal({
            message: wallet.mnemonic,
            signature: signature.signature,
            address: wallet.address,
          });
        } else {
          response = await api.signUpLocal();
          this.setWallet(response.wallet);
        }
        this.setToken(response.token);
        const lang = localStorage.getItem("lang");
        localStorage.removeItem("qrCode");
        await this.$router.push({
          path: `/${lang ? lang : "en"}/home`,
          query: this.qrCode ? { code: this.qrCode } : {},
        });
      } catch (e) {
        console.log(e);
      }
    },
  },
  mounted() {
    if (this.qrCode) {
      localStorage.setItem("qrCode", this.qrCode);
    }
  },
  components: { GoogleSvg },
};
</script>

<style>
.slide-top-enter-active {
  transition: all 0.3s ease;
  transform: translateY(250%) scale(1.6);
}

.fade-enter-active {
  transition: opacity 0.2s ease-in-out;
  opacity: 0.1;
}
</style>
